
import limit from "@/util/judgeLimits.js";
/*
项目管理：poject
*/
//--添加项目
let pro_Add = (()=>{
    return limit.check('mytask_add')
})
//--删除项目
let pro_remove = ((pro_item)=>{
    if(limit.check('project_delete')){
        return true
    }else{
        let user_types = pro_item.user_types
        return limit.check_pro(user_types,'project_delete')
    }
})
//申请加入项目
let pro_applyToPo = (()=>{
    return !limit.isadmin()
})
//证据管理
let pro_evManage = ((pro_item)=>{
    if(limit.check('mytask_evidence')){
        return true
    }else{
        let user_types = pro_item.user_types
        return limit.check_pro(user_types,'pro_mytask_evidence')
    }
    
})
//证据删除
let pro_evDelete = ((pro_item)=>{
    if(limit.check('evidence_delete')){
        return true
    }else{
        let user_types = pro_item.user_types
        return limit.check_pro(user_types,'pro_delete_evidence')
    }
})
//成员管理
let pro_membManage = ((pro_item)=>{
    if(limit.check('mytask_member')){
        return true
    }else{
        let user_types = pro_item.user_types
        return limit.check_pro(user_types,'pro_mytask_member')
    }
})
//成员移除
let pro_memDelete = ((pro_item)=>{
    if(limit.check('pro_member_delete')){
        return true
    }else{
        let user_types = pro_item.user_types
        return limit.check_pro(user_types,'pro_member_delete')
    }
})

/*
证据管理：evidence
*/
//像团队申请证据服务
let ev_applyEvidence = (()=>{
    return !limit.isadmin()
})
//删除数据(前端权限暂时没有控制是删除个人数据还是团队数据)
let ev_deleteData = (()=>{
    return limit.check('deletePersonData') || limit.check('deleteTeamData')
})
//恢复文件
let ev_recyclebin_recover = (()=>{
    return limit.check('recyclebin_recover')
})
/*
团队管理
*/
//授权
let team_accredit = (()=>{
    return limit.check('memberManage_auth')
})
//查看成员证据
let team_lookMemberEv = (()=>{
    return limit.check('memberManage_evidence')
})
//移除成员
let team_removeMember = (()=>{
    return limit.check('memberManage_remove')
})
//邀请成员
let team_inviteMember = (()=>{
    return limit.check('memberManage_invite')
})
//分配空间
let team_distributSpace = (()=>{
    return limit.check('memberManage_space')
})
//编辑用户资料
let team_editMember = (()=>{
    return limit.check('memberManage_edit')
})
//申请空间审核权限
let team_space_applyCheck = (()=>{
    return limit.check('space_apply_check')
})
//申请加入项目审核权限
let team_addpro_applyCheck = (()=>{
    return limit.check('addpro_apply_check')
})
//申请加入团队审核权限
let team_addteam_applyCheck = (()=>{
    return limit.check('addteam_apply_check')
})
/*
钱包权限
*/
//提现
let withDraw = (()=>{
    return limit.check('withDraw')
})
//导出发票记录
let exportInvoice = (()=>{
    return limit.check('exportInvoiceTable')
})

/*
权限设置
*/
//团队角色授权
let limitsSetTeamAuth = (()=>{
    return limit.check('limitsSet_auth')
})
//项目角色授权
let limitsSetProAuth = (()=>{
    return limit.check('limitsSetPro_auth')
})
//团队角色添加
let limitsSetTeamAdd = (()=>{
    return limit.check('limitsSet_team_add')
})
//项目角色添加
let limitsSetProAdd = (()=>{
    return limit.check('limitsSet_pro_add')
})
//团队角色删除
let limitsSetTeamDelete = (()=>{
    return limit.check('limitsSet_team_delete')
})
//项目角色删除
let limitsSetProDelete = (()=>{
    return limit.check('limitsSet_pro_delete')
})
//团队角色编辑
let limitsSetTeamEdit = (()=>{
    return limit.check('limitsSet_team_edit')
})
//项目权限编辑
let limitsSetProEdit = (()=>{
    return limit.check('limitsSet_pro_edit')
})
export default {
    //limit权限
    limit:{
        limitsSetTeamAuth,  //团队角色授权
        limitsSetProAuth,  //项目角色授权
        limitsSetTeamAdd,  //团队角色添加
        limitsSetProAdd,  //项目角色添加
        limitsSetTeamDelete,  //团队角色删除
        limitsSetProDelete,  //项目角色删除
        limitsSetTeamEdit,  //团队角色编辑
        limitsSetProEdit,  //项目权限编辑
    },
    //钱包
    wallet:{
        withDraw:withDraw,//提现
        exportInvoice:exportInvoice//导出发票记录
    },
    //团队管理
    team:{
        accredit:team_accredit,//授权
        lookMemberEv:team_lookMemberEv,//查看成员证据
        removeMember:team_removeMember,//移除成员
        inviteMember:team_inviteMember,//邀请成员
        distributSpace:team_distributSpace,//分配空间
        editMember:team_editMember,//编辑用户资料
        spaceApplyCheck:team_space_applyCheck,//申请空间审核权限
        addproApplyCheck:team_addpro_applyCheck,//申请加入项目审核权限
        addteamApplyCheck:team_addteam_applyCheck,//申请加入团队审核权限
    },
    //证据管理
    evidence:{
        applyEvidence:ev_applyEvidence,//像团队申请证据服务
        ev_deleteData:ev_deleteData,//删除数据
        recyclebin_recover:ev_recyclebin_recover,//恢复文件
    },
     //项目管理
     poject: {
        addPro:pro_Add,///添加项目
        removePro:pro_remove,//删除项目
        applyToPo:pro_applyToPo,///申请加入项目
        evManage:pro_evManage,//证据管理
        evDelete:pro_evDelete,//证据删除
        memManage:pro_membManage,//成员管理
        memDelete:pro_memDelete,//成员移除
    },
   
}

