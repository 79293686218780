
import link from "@/api/links.js"
import apiUrl from "@/api/url.js"
import store from "@/store";
import router from "@/router";

//获取用户信息
const getUserInfo = (getFinish) => {
    link(apiUrl.getUserInfo, "post",{}, {}).then((ok) => {
        if (ok.code == 1) {
            store.dispatch('LoginIn',ok.data)
            if(getFinish){
                getFinish()
            }
        }
    })
}
//退出登录
const loginOut = (loginOutFinish) => {
    store.dispatch('LoginOut')
    store.commit("SET_MENUINDEX", 0)
    router.push("/login")
    if(loginOutFinish){
        loginOutFinish()
    }
}

export default {
    getUserInfo: getUserInfo,
    loginOut:loginOut
}