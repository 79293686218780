
const HomeModule: object = {
    state:{
        expand: true,
        menuSelectItem: {},
        muneIndex:0
    },
    mutations:{
        //存储菜单中左右伸缩的bool值
        SET_EXPAND_BOOL(state:any){
            state.expand = !state.expand;
        },
        //存储左边的菜单选择项目
        SET_MENUSELECTINDEX(state:any,item:any){
            state.menuSelectItem = item;
        },
         //存储左边的菜单选择项目
         SET_MENUINDEX(state:any,index:number){
            state.muneIndex = index;
        },
    },
    actions:{

    },
    getters:{

    }
}

export default HomeModule