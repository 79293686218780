import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import asyncRouter from "@/util/asyncRouter.js"
import store from "@/store";
import getuserinfo from "@/util/getuserinfo.js"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    children: [],
    meta: {
      'router': true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      'router': true
    }
  },
  {
    path: "/",
    redirect: "/home",
    meta: {
      'router': false
    }
  }
]
const router = createRouter({
  // history: createWebHashHistory(process.env.VUE_APP_BASE_UPL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
//新建了develop分支 以及dev_p和dev_p分支,两个分支分别代表个人和团队，公共的代码由develop分支开发merge
router.beforeEach((to, from, next) => {
  console.log('beforeEach to', to)
  console.log('beforeEach from', from)
  //路由守卫登录信息判断
  let state: any = store.state
  let token = state.LoginModule.token ? state.LoginModule.token : ''
  if (token == '') {
    if (to.fullPath == '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
    
  } else {
    // 路由守卫（动态路由）
    let arr = router.options.routes[0].children
    if (arr?.length == 0) {
      let getSuccess = () => {
        if (to.fullPath == '/home') {
          next({ path: '/home/control' })
        } else {
          next({ ...to, replace: true })
        }
      }
      //获取后端路由数据
      asyncRouter(getSuccess)
    } else {
      next()
    }
  }
})
export default router
