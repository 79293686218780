
const GlobalHomeModules: object = {
    state: {
        appDownloadImgShow: false,
        switchTeamShow: false,
        personInfoShow: false,
        payServiceShow: false,//购买证据服务
    },

    getters: {
        getAppDownloadImgShow(state: any) {
            return state.appDownloadImgShow
        },
        getSwitchTeamShow(state: any) {
            return state.switchTeamShow
        },
        getPersonInfoShow(state: any) {
            return state.personInfoShow
        },
        getPayServiceShow(state: any) {
            return state.payServiceShow
        },
    },
    mutations: {
        set_appDownloadImgShow(state: any, appDownloadImgShow: boolean) {
            state.appDownloadImgShow = appDownloadImgShow
        },
        set_switchTeamShow(state: any, switchTeamShow: boolean) {
            state.switchTeamShow = switchTeamShow
        },
        set_personInfoShow(state: any, personInfoShow: boolean) {
            state.personInfoShow = personInfoShow
        },
        set_payServiceShow(state: any, payServiceShow: boolean) {
            state.payServiceShow = payServiceShow
        },
    },
    actions: {
        set_appDownloadImgShow(context: any, appDownloadImgShow: boolean) {
            context.commit('set_appDownloadImgShow', appDownloadImgShow)
        },
        set_switchTeamShow(context: any, set_switchTeamShow: boolean) {
            context.commit('set_switchTeamShow', set_switchTeamShow)
        },
        set_personInfoShow(context: any, personInfoShow: boolean) {
            context.commit('set_personInfoShow', personInfoShow)
        },
        set_payServiceShow(context: any, payServiceShow: boolean) {
            context.commit('set_payServiceShow', payServiceShow)
        },
    },
}

export default GlobalHomeModules