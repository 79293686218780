
let apiUrl={
    menuList:"/index/menu",//获取菜单路由
    login:"/login/index",//登录
    menuAdd:"/menu/add",//添加菜单
    menuEdit:"/menu/edit",//编辑菜单
    menuStatus:"/menu/status",//菜单状态管理
    menuDelete:"/menu/delete",//菜单删除
    menuOrder:"/menu/order",//菜单排序
    indexStatistics:"/index/statistics",//数据概览
    userStatistics:"/statistics.user/index",//用户数据统计
    orderStatistics:"/statistics.order/index",//订单数据统计
    tradeStatistics:"/statistics.trade/index",//交易数据统计
    spaceStatistics:"/statistics.space/index",//空间数据统计
    databaseIndex:"/database.index/index",//证据数据
    databaConfirm_view:"/database.index/confirm_view",//查看确认涵
    databa_restore:"/database.index/restore",//证据数据恢复
    databa_apply:"/database.index/apply",//证据审核，禁用

    userListIndex:"/user.index/index",//用户列表
    usergetparentIndex:"/user.index/getparent",//获取推荐人列表
    usergetparent:"/user.index/parent",//绑定荐人
    contract_order_add:"/contract.order/add",//添加空间充值
    user_repass:"/user.index/repass",//重置密码
    user_changeState:"/user.index/state",//修改用户状态
    user_add:"/user.index/add",//添加用户

    uploadFile:"/upload/file",//单文件上传

    rebate_index:"/user.rebate/index",//用户返佣记录
    rebate_count:"/user.rebate/count",//用户返佣统计数据

    score_clist:"/user.score/clist",//用户积分记录（外层）
    score_index:"/user.score/index",//用户积分记录(明细)
    score_count:"/user.score/count",//用户返佣统计数据

    space_index:"/user.space/index",//空间使用记录
    space_count:"/user.space/count",//用户空间统计数据

    teamindex_index:"/team.index/index",//团队列表
    teamindex_state:"/team.index/state",//团队状态修改
    teamspace_add:"/team.space/add",//团队状态修改
    teamsetsuper:"/team.index/setsuper",//修改超管    
    teamindex_edit:"/team.index/edit",//编辑团队
    teamuser_index:"/team.user/index",//团队用户
    teamauth_list:"/team.auth/index",//默认角色列表
    teamauth_add:"/team.auth/add",//添加角色
    teamauth_edit:"/team.auth/edit",//编辑角色
    teamauth_remove:"/team.auth/remove",//删除角色
    teamauth_status:"/team.auth/state",//角色状态修改
    teamproject_list:"/team.project/index",//项目列表

    newsItem_list:"/news.item/index",//文章列表
    newsItem_recommend:"/news.item/recommend",//是否推荐文章
    newsItem_state:"/news.item/state",//是否激活
    newsItem_sort:"/news.item/sort",//文章排序
    newsItem_remove:"/news.item/remove",//文章删除
    newsMark_index:"/news.mark/index",//标签列表
    newsMark_sort:"/news.mark/sort",//标签排序
    newsMark_state:"/news.mark/state",//标签状态
    newsMark_add:"/news.mark/add",//添加文章标签
    newsMark_remove:"/news.mark/remove",//删除文章标签
    newsMark_edit:"/news.mark/edit",//编辑文章标签
    newsitem_add:"/news.item/add",//添加文章
    newsitem_edit:"/news.item/edit",//编辑文章

    newbieIndexList:"/newbie.index/index",//新手指南图文记录
    newbieIndexAdd:"/newbie.index/add",//新手指南添加
    newbieIndexEdit:"/newbie.index/edit",//新手指南编辑
    newbieIndexState:"/newbie.index/state",//新手指南状态修改
    newbieIndexSort:"/newbie.index/sort",//新手指南排序
    newbieIndexRemove:"/newbie.index/remove",//新手指南删除

    newbieVideoList:"/newbie.video/index",//视频列表
    newbieVideoAdd:"/newbie.video/add",//添加视频
    newbieVideoEdit:"/newbie.video/edit",//编辑视频
    newbieVideoState:"/newbie.video/state",//视频状态修改
    newbieVideoSort:"/newbie.video/sort",//视频排序
    newbieVideoRemove:"/newbie.video/remove",//视频删除

    newbieImageList:"/newbie.image/index",//图片列表
    newbieImageAdd:"/newbie.image/add",//添加图片
    newbieImageEdit:"/newbie.image/edit",//编辑图片
    newbieImageState:"/newbie.image/state",//图片状态修改
    newbieImageSort:"/newbie.image/sort",//图片排序
    newbieImageRemove:"/newbie.image/remove",//图片删除

    contract_order_list:"/contract.order/index",//个人服务订单记录
    contract_space_list:"/user.space/index",//空间使用记录

    contract_index_list:"/contract.index/index",//服务数据列表
    contract_index_add:"/contract.index/add",//添加服务数据
    contract_index_edit:"/contract.index/edit",//编辑服务数据
    contract_index_remove:"/contract.index/remove",//删除服务数据

    coupon_index_list:"/coupon.index/index",//卡券数据列表
    coupon_index_add:"/coupon.index/add",//添加卡券数据
    coupon_index_delete:"/coupon.index/delete",//删除卡券数据

    slider_index_types:"/slider.index/types",//轮播类型
    slider_index_list:"/slider.index/index",//轮播列表
    slider_index_detail:"/slider.index/detail",//轮播详情
    slider_index_update:"/slider.index/update",//轮播跟新

    page_index_types:"/page.index/types",//页面类型
    page_index_detail:"/page.index/detail",//页面详情
    page_index_update:"/page.index/update",//页面跟新

    user_transfer_list:"/user.transfer/index",//提现记录
    user_transfer_auditStatus:"/user.transfer/auditStatus",//提现审核操作
    user_transfer_count:"/user.transfer/count",//提现数据
    user_transfer_auditPayment:"/user.transfer/auditPayment",//打款标记操作

    user_transfer_config:"/user.transfer/config",//用户提现配置
    user_transfer_payment:"/user.transfer/payment",//微信转账配置
    user_transfer_alipay:"/user.transfer/alipay",//支付宝转账配置
    queue_transfer_status:"/queue.transfer/status",//任务状态（判断是否开启自动到账功能）
    queue_transfer_start:"/queue.transfer/start",//开启任务状态（开启自动到账功能）
    queue_transfer_close:"/queue.transfer/close",//关闭任务状态（关闭自动到账功能）

    
    feedback_log_index:"/invoice.log/index",//申请开票记录
    feedback_logapply:"/invoice.log/apply",//审核
    invoiceOrderLog:"/invoice.log/order",//开票订单列表

    teamindex_apply:"/team.index/apply",//团队审核

    user_legalize_index:"/user.legalize/index",//用户认证信息列表

    user_legalize_state:"/user.legalize/state",//修改认证状态

    feedback_index :"/feedback.index/index",//反馈记录
    feedback_followup :"/feedback.index/followup",//反馈信息跟进
    feedback_remove :"/feedback.index/remove",//删除反馈信息跟进
    manamgeuser_edit :"/user/edit",//管理员编辑信息
    project_userindex :"/team.project_user/index",//项目成员管理 
    project_fileindex :"/team.project_file/index",//项目证据管理 
    project_statusChange :"/team.project/status",//更改项目状态

    //维权悬赏
    task_infoindex :"/task.info/index",//维权悬赏申请开通记录
    task_infoapply :"/task.info/apply",//审核开通

    
}
export default apiUrl
