import { createStore } from 'vuex'
import HomeModule from './modules/HomeModule'
import LoginModule from './modules/LoginModule'
import PaginationModule from './modules/PaginationModule'
import UploadModule from './modules/UploadModule'
import GlobalHomeModules from './modules/GlobalHomeModules'

import createPersistedState from 'vuex-persistedstate'

const storageState = createPersistedState({
  storage: window.localStorage,
  paths: ['HomeModule','LoginModule'],
})

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    HomeModule,
    LoginModule,
    PaginationModule,
    UploadModule,
    GlobalHomeModules
  },
  plugins: [
    storageState
  ]

})
