
import store from "@/store";

//判断角色 
const jhf_judgeLimits = (limitName) => {
    if (!limitName) return false
    //判断是不是管理员，如果是超级管理员的话就返回true的权限
    if (isadmin()) {
        return true
    }
    if (!store.state.LoginModule.user.auth_info) { return false }
    let limitArr = store.state.LoginModule.user.auth_info
    if (limitArr.constructor != Array) {
        return false
    }
    return dg_limitArr(limitArr, limitName)
}
const dg_limitArr = (limitArr, limitName,flag) => {
    for (let i = 0; i < limitArr.length; i++) {
        const element = limitArr[i];
        if (element.node == limitName) {
            return true
        }
        if (element.sub) {
            flag = dg_limitArr(element.sub, limitName,flag)
        }

    }
    return flag
}
//项目权限的获取，
//在当前项目中国的角色arr
const jhfGetProAuth = ((roleArr, limitName) => {
    if (!roleArr) return false
    if (!limitName) return false
    //判断是不是管理员，如果是超级管理员的话就返回true的权限
    if (isadmin()) {
        return true
    }

    if (!store.state.LoginModule.user.project_auth) { return false }
    let limitDic = store.state.LoginModule.user.project_auth
    for (let i = 0; i < roleArr.length; i++) {
        let roleId = roleArr[i]+''
        let listNodes = limitDic[roleId]
        if (listNodes.nodes) {
           for (let j = 0; j < listNodes.nodes.length; j++) {
                let authName = listNodes.nodes[j]
                
                if(limitName == authName){
                    return true
                }
           }
        }
        
    }
    return false
})
//判断是不是管理员
let isadmin = (()=>{
    if (!store.state.LoginModule.user) { return false }
    //判断是不是管理员，如果是超级管理员的话就返回true的权限
    if (!store.state.LoginModule.user.team_info) { return false }
    if (store.state.LoginModule.user.team_info.isadmin == 1) {
        return true
    }
    return false
})

export default {
    check: jhf_judgeLimits,
    check_pro: jhfGetProAuth,
    isadmin:isadmin
}