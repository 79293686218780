
const UploadModule: object = {
    state: {
        isShow: false,
        showDown:false,
        progressMode: [
            
        ],
    },
    getters: {
        getIsShow(state: any) {
            return state.isShow
        },
        getShowDown(state: any) {
            return state.showDown
        },
        getProgressMode(state: any) {
            return state.progressMode
        },
    },
    mutations: {
        set_up_isShow(state: any, isShow: boolean) {
            state.isShow = isShow
        },
        set_up_showDown(state: any, showDown: boolean) {
            state.showDown = showDown
        },
        set_up_progressMode(state: any, progressMode: any) {
            state.progressMode = progressMode
        },
    },
    actions: {
        set_up_isShow(context: any, isShow: boolean) {
            context.commit('set_up_isShow', isShow)
        },
        set_up_showDown(context: any, showDown: boolean) {
            context.commit('set_up_showDown', showDown)
        },
        set_up_progressMode(context: any, progressMode: any) {
            context.commit('set_up_progressMode', progressMode)
        },
    },
}

export default UploadModule