import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/*
Violation ] Added non-passive event listener to ascroll- blocking 'mousewheel’event Consider marking event handler as ’ passive’to make the page more responsive. --控制台报错解决方法

这个错误翻译过来的的意思就是：[违规]在ascroll中添加了非被动事件侦听器-阻塞“mousewheel”事件考虑将事件处理程序标记为“passive”以使页面更具响应性。
解决方法：
npm install --save default-passive-events
在main.js中引用:
import 'default-passive-events'
*/
import 'default-passive-events'

import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'
//全局变量
import g_auth from "@/util/judgeLimits_sub.js";

const app = createApp(App)
app.config.globalProperties.$g_auth = g_auth;

app.use(store).use(router).mount('#app')
