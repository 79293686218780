//Vue3 用Vuex.Store 保存token和用户信息
//https://blog.csdn.net/admans/article/details/128061593
//如果想持久保存，可以使用 vuex-persistedstate 或 vuex-persist  插件
//vuex-persistedstate使用
// npm install vuex-persistedstate --save
const LoginModule: object = {
    state: {
        token: null,
        user: null,
        isLogin: false
    },
    getters: {
        getToken(state: any) {
            return state.token
        },
        getUser(state: any) {
            return state.user
        },
        getIsLogin(state: any) {
            return state.token !== null && state.user !== null
        }
    },
    mutations: {
        SetToken(state: any, token: string) {
            state.token = token
        },
        LoginIn(state: any, user: any) {
            state.user = user
            state.isLogin = true
        },
        LoginOut: (state: any) => {
            state.token = null
            state.user = null
            state.isLogin = false
        }
    },
    actions: {
        SetToken(context: any, token: string) {
            context.commit('SetToken', token)
        },
        LoginIn(context: any, user: any) {
            context.commit('LoginIn', user)
        },
        LoginOut(context: any) {
            context.commit('LoginOut')
        }
    },
}

export default LoginModule