
const PaginationModule: object = {
    state: {
        currentPage: 1,
        pageSizes: [10,12,15,20,25,30,35],
        pageSize: 15,
        total: 0,
        previousPage:{currentPage:1,total:0}//上一页的page和total信息，里面两个字段
    },
    getters: {
        getCurrentPage(state: any) {
            return state.currentPage
        },
        getPageSizes(state: any) {
            return state.pageSizes
        },
        getPageSize(state: any) {
            return state.pageSize
        },
        getTotal(state: any) {
            return state.total
        },
        getPreviousPage(state: any) {
            return state.previousPage
        }
    },
    mutations: {
        CurrentPageChange(state: any, currentPage: number) {
            state.currentPage = currentPage
        },
        SetPageSizes(state: any, pageSizes: number[]) {
            state.pageSizes = pageSizes
        },
        SetPageSize: (state: any, pageSize: number) => {
            state.pageSize = pageSize
        },
        SetTotal: (state: any, total: number) => {
            state.total = total
        },
        SetPreviousPage: (state: any, previousPage: any) => {
            state.previousPage = previousPage
        }
    },
    actions: {
        CurrentPageChange(context: any, currentPage: number) {
            context.commit('CurrentPageChange', currentPage)
        },
        SetPageSizes(context: any, pageSizes: number[]) {
            context.commit('SetPageSizes', pageSizes)
        },
        SetPageSize(context: any, pageSize: number) {
            context.commit('SetPageSize', pageSize)
        },
        SetTotal(context: any, total: number) {
            context.commit('SetTotal', total)
        },
        SetPreviousPage(context: any, previousPage: any) {
            context.commit('SetPreviousPage', previousPage)
        }
    }
}

export default PaginationModule