import axios from "axios";
import store from "@/store";
import router from "@/router";
import getuserinfo from "@/util/getuserinfo.js";
console.log("axios类最外层引入的store对象", store);
let axiosUrl = '';
if (process.env.NODE_ENV === 'development') {
  axiosUrl = process.env.VUE_APP_API;
} else {
  axiosUrl = process.env.VUE_APP_API;
}
const service = axios.create({
  baseURL: axiosUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
    'api-type': 'web'
  }
});
let loadingInstance = null;
// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  let token = store.state.LoginModule.token ? store.state.LoginModule.token : '';
  config.headers["api-token"] = token;
  if (config.url != '/payment/result') {
    loadingInstance = ElLoading.service({
      lock: true,
      text: '加载中...',
      // spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  }
  return config;
}, function (error) {
  if (loadingInstance) {
    loadingInstance.close();
  }
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  if (loadingInstance) {
    loadingInstance.close();
  }
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  let data = response.data;
  console.log("收到请求数据", data);
  if (Object.keys(data).length != 0) {
    if (data.code == 0) {
      ElMessage(data.info);
    } else if (data.code == 401) {
      ElMessage(data.info);
      //清空登录数据
      getuserinfo.loginOut();
    }
    return data;
  } else {
    ElMessage("数据解析失败");
    return {};
  }
}, function (error) {
  if (loadingInstance) {
    loadingInstance.close();
  }
  if (error.response) {
    // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
    switch (error.response.status) {
      case 404:
        alert('当前路径有误');
        break;
      case 500:
        alert('服务器出错，请稍后重试');
        break;
      default:
        alert('未知错误' + error.response.status);
        break;
    }
  } else if (error.request) {
    // 请求已经成功发起，但没有收到响应
    alert('请求出错，未收到相应');
  } else {
    // 发送请求时出了点问题
    alert('请求异常了！！！');
  }
  return Promise.reject(error);
});
export default service;