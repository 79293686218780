

import router from "@/router";
import link from "@/api/links.js"
import apiUrl from "@/api/url.js"

///menu/index
//获取动态的路由结构
let getmuneList = (getSuccess) => {
    link(apiUrl.menuList, "post", {}, {}).then((ok) => {
        if (ok.code == 1) {
            getAsyncRoutes(ok.data)
            getSuccess()
        }
    })
}
let getAsyncRoutes = (routes) => {
    //routes为后端返回的路由表
    const res = []
    routes.forEach((route) => {
        let children = null
        if (route.sub) {
            children = creatRouter(route)
        }
        if (route.params.indexOf('{') == -1) {
            return
        }
        let meta = JSON.parse(route.params)
        if(meta.router && meta.router == true){
            res.push({
                path: route.url,
                name: route.node,
                component: () => import(`@/views${route.page}`),
                meta: meta,
                children: children
            })
        } 
    })
    const _router = router.options.routes
    _router[0].children = res
    router.addRoute(_router[0])
    console.log('_routerc信息', _router)
}

//处理路由数据(递归获取数据)
let creatRouter = ((route) => {
    if (!route.sub) {
        return null
    }
    let children = []
    route.sub.forEach((menu) => { // 二级菜单需匹配页面
        let nextChild = null
        if (menu.sub) {
            nextChild = creatRouter(menu)
        }
        if (menu.params.indexOf('{') == -1) {
            return
        }
        let submate = JSON.parse(menu.params)
        if(submate.router && submate.router == true){

            children.push({
                path: menu.url,
                name: menu.node,
                component: () => import(`@/views${menu.page}`),
                meta: submate,
                children: nextChild
            })
        }
    })
    for (let i = 0; i < children.length; i++) {
        let item = children[i]
        if(item.meta.router == true && item.meta.router == true){
            //设置重定向
            children.push({
                path: route.url,
                redirect: children[i].path,
                meta: { router: false }
            })
            break
        }
    }
    return children
})


export default getmuneList